import 'amfe-flexible'
import Vue from "vue";
import App from "./App.vue";
import router from "./router"; // 路由
import store from "./store/index"; // vuex
import { v3 } from "@/api"; // vuex
import VueI18n from "vue-i18n";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/font/iconfont.css";
import { messages } from "@/assets/js/data"; // 语言包
import findKey from "lodash/findKey.js"; // 多语言相关组件
import { getOrigin } from "@/utils/getUserAgent"; // 全局注册组件
import Cookies from "js-cookie";
import axios from 'axios';
// countries: (this.$store.state.base.LanguageCode != 'zh-cn' && this.$store.state.base.LanguageCode != 'zh' && this.$store.state.base.LanguageCode != 'zh-tw' && this.$store.state.base.LanguageCode != 'zh-hk') ? require('../../static/json/areaCodeEn.json') : require('../../static/json/areaCodeCN.json'), // 中文国家列表

import {
  Divider,
  Icon,
  Toast,
  Dialog,
  Notify,
  PasswordInput,
  Form,
  Field,
  NumberKeyboard,
  DatetimePicker,
  Picker,
  Swipe,
  SwipeItem,
  Popup,
  Popover,
  ImagePreview,
  Lazyload,
  SwipeCell,
  Tab,
  Tabs,
} from "vant";
import "vant/lib/index.css";
import "@/assets/css/tailwind.css";
import "@/assets/css/reset.css";
import "@/utils/global";
// import VueAwesomeSwiper from 'vue-awesome-swiper'
import "hammerjs";
// Vue.use(Vant);
Vue.prototype.$Dialog = Dialog;
Vue.prototype.$Toast = Toast;
Vue.prototype.$Notify = Notify;
Vue.prototype.$axios = axios;
// import 'lib-flexible'

Vue.use(Divider);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(SwipeCell);
Vue.use(Lazyload);
Vue.config.productionTip = false;
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(DatetimePicker);
Vue.use(Dialog);
Vue.use(Picker);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Popup);
Vue.use(Popover);
Vue.use(ImagePreview);
Vue.use(VueI18n);
Vue.prototype.$cookies = Cookies; // 设置cookie
Vue.prototype.$store = store; // vuex
Vue.prototype.$v3 = v3; // vuex

Vue.use(ElementUI);
const commit = Vue.prototype.$store;
// 处理域名
if (window.location.host.includes(".com")) {
  // 设置获取主域名
  const host = window.location.host.split(".").splice(1, 2);
  commit.commit("base/sethost", host.join("."));
}
let getdata = {};
const appagentparam = getOrigin(); // app进来的数据test
// const vux = commit.state.base;
// console.log(appagentparam, 8866555)

let lang = ""; // 定义语言
const langList = store.state.base.langList;
// console.log('langList: ', langList);
lang = window.location.href
  .replace("http://", "")
  .replace("https://", "")
  .split("/")[1]
  .split("?")[0];
console.log("lang: ", lang);
// ---取浏览器语言
let navlang = navigator.language.split(",")[0].toLowerCase();
console.log("navlang: ", navlang);
if (navlang != "zh-cn" && navlang.includes("-")) {
  navlang = navlang.split("-")[0];
}
if (!langList.includes(navlang)) navlang = "en";
console.log(
  "store.state.base.langList.includes(lang): ",
  store.state.base.langList.includes(lang),
);

// ---------------------------------
lang =
  lang && store.state.base.langList.includes(lang)
    ? lang.toLocaleLowerCase()
    : navlang
      ? navlang
      : "en";
if (lang === "zh-tw" || lang === "zh-hk") {
  lang = "zh";
  window.location.href = `/${lang}`;
}
if (appagentparam?.BasicData) {
  // 判断如果为app传入contentcookie则使用app数据
  Object.assign(getdata, appagentparam);
  const userInfo = {
    userId: getdata?.userId || "",
    nick: getdata?.nick || "",
    avatar: getdata?.avatar || "",
  };
  console.log("userInfo: app 传值", userInfo);
  commit.commit("base/setisApp", true);
  commit.commit("base/setUser", userInfo);

  const navHeight = parseFloat(getdata.navHeight).toFixed(2);

  commit.commit("base/getNavHeight", navHeight); // 设置为APP
  if(getdata?.countryName){
    commit.commit("base/setuserCountInfo", {
      flag: getdata?.falg,
      code: getdata?.phonecode,
      twoCharCode: getdata?.twoCharCode,
      countryCode:getdata?.countryCode,
      name:getdata?.countryName
    }); // 设置
  }else{
    commit.commit("base/setuserCountInfo", {
      countryCode: "156",
      twoCharCode: "CN",
      code: "0086",
      name: "中国",
      flag: "https://img.souhei.com.cn/flag/7d8833382673bab2/CN.png_wiki-template-global",
      "flag-c":
      "https://img.souhei.com.cn/flag-c/7d8833382673bab2.png_wiki-template-global",
    }); // 设置
  }
  
  commit.commit("base/setwikinum", getdata?.wikinum); // 设置天眼号
  commit.commit("base/setCountryCode", getdata?.countrycode); // 设置国家
  commit.commit("base/setMyCountry", getdata?.myCountry); // 设置ip国家

  // if (getdata.enterpriseCode) { // 是否有code
  //   commit.commit('base/setEnterpriseCode', getdata.enterpriseCode); // 设置企业code
  // } else {
  //   commit.commit('base/setEnterpriseCode', ''); // 设置企业code
  // }

  commit.commit("base/setLangCode", getdata.language.toLowerCase()); // 存入当前语言
  lang = getdata.language.toLowerCase();


  let countries =  getdata.language.toLowerCase() != 'zh-cn' && getdata.language.toLowerCase() != 'zh' && getdata.language.toLowerCase() != 'zh-tw' && getdata.language.toLowerCase() != 'zh-hk' ? require('@/static/json/areaCodeEn.json') : require('@/static/json/areaCodeCN.json') // 中文国家列表
  console.log('countries: ', countries);

} else {
  commit.commit("base/setLangCode", lang); // 存入当前语言
}

if (getdata.userId) commit.commit("base/setUserid", getdata.userId); // 设置userid

// ...对数据进行存储

const i18n = new VueI18n({
  locale: lang,
  messages,
  silentTranslationWarn: true,
});
VueI18n.prototype.translation = function (value) {
  let en = require("@/assets/languages/TXT_zh-CN.json");
  let val = findKey(en, function (o) {
    return o === value;
  });
  if (!val) {
    return value + "(前端未翻译)";
  }
  return this.$t(val);
};
Vue.prototype.$v = i18n.translation; // i18n
document.querySelector("html").setAttribute("lang", lang);
i18n.location = lang;
if (lang === "ar") {
  document.querySelector("html").setAttribute("dir", "rtl");
} else {
  document.querySelector("html").setAttribute("dir", "ltr");
}
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
