import STRING from "string";
let originParams = {};
let headers = {};
export const getOrigin = function () {
  let userAgents = navigator.userAgent;
  console.log("userAgents: ", userAgents);

  if (process.env.NODE_ENV === "development") {
    //只允许再测试环境
    // userAgents =
      // 'userAgents:  FXEYE-BIB-IOS-WIKIFX{"wikistock":"ios","language":"zh-CN","userId":null,"wikinum":null,"falg":"https://img.tech002.com/flag/7d8833382673bab2/CN.png_wiki-template-global","phonecode":"0086","hasWhatsApp":false,"showname":"","LanguagePreference":"zh-CN","avatar":null,"countrycode":"156","twoCharCode":"CN","BasicData":"0,1751,3,352,0,edf80310-52ef-11ef-bf7b-71e510c5dced,0","navHeight":59.0}{"wikistock":"ios","language":"zh-CN","userId":null,"wikinum":null,"falg":"https://img.tech002.com/flag/7d8833382673bab2/CN.png_wiki-template-global","phonecode":"0086","hasWhatsApp":false,"showname":"","LanguagePreference":"zh-CN","avatar":null,"countrycode":"156","twoCharCode":"CN","BasicData":"0,1751,3,352,0,edf80310-52ef-11ef-bf7b-71e510c5dced,0","navHeight":59.0}';
  }

  if (
    userAgents.includes("FXEYE-BIB-IOS-WIKIFX") ||
    userAgents.includes("FXEYE-BIB-ANDROID-WIKIFX") ||
    userAgents.includes("WIKIBIT-BIB-IOS") ||
    userAgents.includes("WIKIBIT-BIB-ANDROID")
  ) {
    originParams = "{" + STRING(userAgents).between("{", "}").s + "}";
    originParams = JSON.parse(originParams);
    originParams.language = originParams.language?.toLowerCase();
    console.log("originParams: ", originParams);
    let newArr = [];
    if (originParams?.labels?.length > 0) {
      originParams?.labels.forEach((el) => {
        newArr.push({
          data: [
            {
              labelName: el,
            },
          ],
        });
      });
    }

    if (originParams.language == "zh-tw" || originParams.language == "zh-hk")
      originParams.language = "zh";
    headers =
      {
        language: originParams.language || "zh-cn",
        userId: originParams.userId || null,
        countrycode: originParams.countrycode, // 数字code
        twoCharCode: originParams.twoCharCode, // 英语code
        wikinum: originParams.wikinum || null,
        falg: originParams.falg,
        phonecode: originParams.phonecode,
        myCountry: originParams.countrycode,
        LanguagePreference: originParams.LanguagePreference,
        BasicData: originParams.BasicData,
        navHeight: originParams.navHeight,
        nick: originParams?.nick || "",
        avatar: originParams?.avatar || "",
        countryName: originParams?.countryName || ""
      } || {};
    console.log(headers, "headers");
    console.log("userAgents::: ", userAgents);
    return headers;
  }
};
