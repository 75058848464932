import Vue from "vue";
const offsetGMT = new Date().getTimezoneOffset() * 60 * 1000;
const now = new Date().getTime() - offsetGMT;
import areaCodeCN from "@/static/json/areaCodeCN.json"; // 引入国家列表（中文版）
import areaCodeEN from "@/static/json/areaCodeEn.json"; // 引入国家列表（英文版）
// lodash一些实用方法：
// _.add(number1, number2) // 两数相加
// _.subtract(number1, number2) // 两数相减
// _.multiply(number1, number2) // 两数相乘
// _.divide(number1, number2) // 两数相除
// _.ceil(number, 2) // 根据精度值向上舍入number
// _.floor(number, 2) // 根据精度值向下舍入number
// _.round(number, 2) // 根据精度值四舍五入number
// _.max(array) // 计算简单数组中的最大值
// _.min(array) // 计算简单数组中的最小值
// _.sum(array) // 计算简单数组值的总和
// _.sumBy([{ n: 1 }, { n: 2 }], 'n') // 根据第二个参数值作为条件返回总和
// _.maxBy([{ n: 1 }, { n: 2 }], 'n') // 根据第二个参数值作为条件返回最大的值
// _.minBy([{ n: 1 }, { n: 2 }], 'n') // 根据第二个参数值作为条件返回最小的值
// _.cloneDeep([{ n: 1 }, { n: 2 }]) // 深拷贝
// _.split('a-b-c', '-', 2) // 根据分隔符拆分字符串，并限制返回数组的长度

// 公用方法
export const currProject = {
  momentTime(timestamp, type = 0) {
    // timestamp传时间戳--格式化时间type为0默认，实堪=1；快讯为2；财经日历为3
    // console.log(offsetGMT, 9199, new Date().getTimezoneOffset(), new Date(now))
    if (!timestamp) return;
    if (String(timestamp).includes("T")) {
      timestamp = Date.parse(timestamp);
    }
    if (`${timestamp}`.length < 12) timestamp = timestamp * 1000;
    const timer = new Date(timestamp - offsetGMT);
    const yyyy = timer.getUTCFullYear(); // 年
    const mm =
      timer.getUTCMonth() + 1 > 9
        ? timer.getUTCMonth() + 1
        : "0" + (timer.getUTCMonth() + 1); // 月
    const dd =
      timer.getUTCDate() > 9 ? timer.getUTCDate() : "0" + timer.getUTCDate(); // 日
    const hh =
      timer.getUTCHours() > 9 ? timer.getUTCHours() : "0" + timer.getUTCHours(); // 时
    const min =
      timer.getUTCMinutes() > 9
        ? timer.getUTCMinutes()
        : "0" + timer.getUTCMinutes(); // 分
    const ss =
      timer.getUTCSeconds() > 9
        ? timer.getUTCSeconds()
        : "0" + timer.getUTCSeconds(); // 秒
    let time = "";
    if (type == 0) {
      const lang = this.$store.state.base.LanguageCode;
      console.log("lang:1221 ", lang);
      if (lang == "zh-cn" || lang == "zh-tw" || lang == "zh-hk") {
        const momen = now - timer;
        // console.log(momen, 666)
        switch (true) {
          case momen >= 0 && momen < 600000:
            time = this.$t("20649");
            break;
          case momen >= 600000 && momen < 3600000:
            time = this.$t("20647").replace("{0}", Math.round(momen / 60000));
            break;
          case momen >= 3600000 && momen < 86400000:
            time = this.$t("20648").replace("{0}", Math.round(momen / 3600000));
            break;
          case momen >= 86400000 && momen < 259200000:
            time = this.$t("20650").replace(
              "{0}",
              Math.round(momen / 86400000),
            );
            break;
          case momen >= 259200000 && momen < 604800000:
            time = this.$t("21748").replace("{0}", 1);
            break;
          default:
            time = `${yyyy}-${mm}-${dd} ${hh}:${min}`;
            break;
        }
      } else {
        time = `${yyyy}-${mm}-${dd} ${hh}:${min}`;
      }
    } else if (type == 1) {
      time = `${yyyy}-${mm}-${dd}`;
    } else if (type == 2) {
      time = `${hh}:${min}:${ss}`;
    } else if (type == 3) {
      time = `${hh}:${min}`;
    } else if (type == 4) {
      time = `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
    }
    return time;
  },

  islonglang() {
    // 判读是否为长语言
    const lang = ["zh-cn", "zh"];
    if (lang.includes(this.$store.state.LanguageCode)) return false;
    return true;
  },

  // 获取国家列表
  areaCodeList() {
    const lang = Vue.prototype.$store.state.LanguageCode.toLowerCase();
    return ["zh-cn", "zh", "zh-tw", "zh-hk"].includes(lang)
      ? areaCodeCN
      : areaCodeEN;
  },
};

Object.keys(currProject).forEach((item) => {
  Vue.prototype[item] = currProject[item];
});

// 金额千分符处理
export const format_number = function (nStr) {
  nStr += "";
  let x = nStr.split(".");
  let x1 = x[0];
  let x2 = x.length > 1 ? "." + x[1] : "";
  var rgx = /(\d+)(\d{3})/; //3就是间隔的位数
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
};

/**
 *
 * @param {*} url js地址
 * @param {*} _callback_success 成功
 * @param {*} _callback_fail 失败
 */
export const loadJs = function (url, _callback_success, _callback_fail) {
  var callback_success = function () {
    console.log("load js success...");
  };
  var callback_fail = function () {
    console.log("load js fail...");
  };
  _callback_success = _callback_success || callback_success;
  _callback_fail = _callback_fail || callback_fail;

  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        _callback_success(xhr.responseText);
      } else {
        _callback_fail(xhr.statusText);
      }
    }
  };
  xhr.send();
};
