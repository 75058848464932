let Index = () => import(/* webpackChunkName: "dome" */ '@/views/_lang/index.vue');
let rate = () => import(/* webpackChunkName: "dome" */ '@/views/_lang/rate.vue');
let ratelist = () => import(/* webpackChunkName: "dome" */ '@/views/_lang/ratelist.vue');
let contact = () => import(/* webpackChunkName: "dome" */ '@/views/_lang/contact.vue');
let forexTool = () => import(/* webpackChunkName: "dome" */ '@/views/_lang/forexTool/index.vue'); // 首页
let calculationPage = () => import(/* webpackChunkName: "dome" */ '@/views/_lang/forexTool/calculationPage.vue'); // 计算页
let resultPage = () => import(/* webpackChunkName: "dome" */ '@/views/_lang/forexTool/resultPage.vue') // 计算结果页
let feedback = () => import(/* webpackChunkName: "dome" */ '@/views/_lang/feedback.vue')
export default [
  { path: '/:lang', name: 'Index', component: Index, meta: { isLogin: false, level: '2,4,999' } },
  { path: '/:lang/rate', name: 'rate', component: rate, meta: { isLogin: false, level: '2,4,999' } },
  { path: '/:lang/ratelist', name: 'ratelist', component: ratelist, meta: { isLogin: false, level: '2,4,999' } },
  { path: '/:lang/contact', name: 'contact', component: contact, meta: { isLogin: false, level: '2,4,999' } },
  { path: '/:lang/forexTool', name: 'forexTool', component: forexTool, meta: { isLogin: false, level: '2,4,999' } },
  { path: '/:lang/calculationPage', name: 'calculationPage', component: calculationPage, meta: { isLogin: false, level: '2,4,999' } },
  { path: '/:lang/resultPage', name: 'resultPage', component: resultPage, meta: { isLogin: false, level: '2,4,999' } },
  { path: '/:lang/feedback', name: 'feedback', component: feedback, meta: { isLogin: false, level: '2,4,999' } },

];
